<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <Avatar size="large" class="primary" icon="pi pi-user-edit" />
                                        </div>
                                        <div class="ml-2">
                                            <div class="text-2xl text-primary font-bold">Edit User</div>
                                            <div class="text-sm text-500"> Modify user record </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
            </template>
            <section class="page-section " >
                <div class="container-fluid">
                    <div class="grid ">
                        <div class="md:col-8 sm:col-12 comp-grid" >
                            <div class="">
                                <div >
                                    <template v-if="!loading">
                                        <div class="grid">
                                            <div class="col-12">
                                                <form ref="observer"  tag="form" @submit.prevent="startRecordUpdate()">
                                                    <!--[form-content-start]-->
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <div class="mb-2 font-bold text-sm">Name *</div>
                                                            <div id="ctrl-name-holder"> 
                                                                <InputText ref="ctrlname" v-model.trim="formData.name"  label="Name" type="text" placeholder=" "      
                                                                class=" w-full" :class="getErrorClass('name')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">Username *</div>
                                                            <div id="ctrl-username-holder"> 
                                                                <check-duplicate v-model="formData.username" check-path="components_data/users_username_exist/" v-slot="checker">
                                                                <InputText ref="ctrlusername" @blur="checker.check" :loading="checker.loading" v-model.trim="formData.username"  label="Username" type="text" placeholder=" "   disabled   
                                                                class=" w-full" :class="getErrorClass('username')">
                                                                </InputText>
                                                                <small v-if="!checker.loading && checker.exist" class="p-error"> Not available</small>
                                                                <small v-if="checker.loading" class="text-500">Checking...</small>
                                                                </check-duplicate>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">Email *</div>
                                                            <div id="ctrl-email-holder"> 
                                                                <check-duplicate v-model="formData.email" check-path="components_data/users_email_exist/" v-slot="checker">
                                                                <InputText ref="ctrlemail" @blur="checker.check" :loading="checker.loading" v-model.trim="formData.email"  label="Email" type="email" placeholder=" "   disabled   
                                                                class=" w-full" :class="getErrorClass('email')">
                                                                </InputText>
                                                                <small v-if="!checker.loading && checker.exist" class="p-error"> Not available</small>
                                                                <small v-if="checker.loading" class="text-500">Checking...</small>
                                                                </check-duplicate>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="mb-2 font-bold text-sm">Photo</div> 
                                                            <div id="ctrl-photo-holder"> 
                                                                <div class="mb-3">
                                                                    <Uploader :class="getErrorClass('photo')" :auto="true" :fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" style="width:100%" label="Choose files or drop files here" :url="$utils.setApiPath('s3uploader/upload/photo')" v-model="formData.photo"></Uploader>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--[form-content-end]-->
                                                    <div v-if="showSubmitButton" class="text-center my-3">
                                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                    </div>
                                                </form>
                                                <slot :submit="submit" :saving="saving"></slot>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="p-3 text-center">
                                            <ProgressSpinner style="width:50px;height:50px" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
		//[--PAGE-IMPORT-STATEMENT--]
	import { PageMixin } from "../../mixins/page.js";
	import { EditPageMixin } from "../../mixins/editpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	import useVuelidate from '@vuelidate/core';
	import { required, email, sameAs, minLength,maxLength,minValue,maxValue,numeric,integer,decimal,ipAddress } from '@vuelidate/validators';
	export default {
		setup () {
			return { v$: useVuelidate() }
		},
		name: 'editUsersPage',
		components: {
			//[--PAGE-COMPONENT-NAME--]
		},
		mixins: [PageMixin, EditPageMixin ],
		props: {
			pageName: {
				type: String,
				default: 'users',
			},
			idName: {
				type: String,
				default: 'user_id',
			},
			routeName: {
				type: String,
				default: 'usersedit',
			},
			pagePath: {
				type : String,
				default : 'users/edit',
			},
			apiPath: {
				type: String,
				default: 'users/edit',
			},
		},
		data() {
            return {
				formData: {
					name: "", username: "", email: "", photo: "", 
				},
				submitted: false,
        	}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "Edit User"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["users/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("users/setCurrentRecord", value);
				},
			},
		},
		validations() {
			let formData =  {
				name: { required },username: { required },email: { required,email },photo: {  },
			}
			return {formData}
		},
		methods: {
			...mapActions('users', ['updateRecord', 'fetchRecord']),
			async startRecordUpdate(){
				this.submitted = true;
				const isFormValid = !this.v$.$invalid;
				if(!isFormValid){
					this.flashMsg("Please complete the form", "Form is invalid", "error");
					return;
				}
				this.saving = true;
				let id = this.id;
				let url = this.apiUrl;
				let payload = this.normalizedFormData();
				let data = { id, url, payload }
				this.updateRecord(data).then(
					(response) => {
						this.saving = false;
						this.flashMsg(this.msgAfterUpdate);
						this.resetForm();
						this.closeDialogs();// close page dialog that if opened
						if(this.redirect) this.navigateTo(`/users`);
					},
					(response) => {
						this.saving = false;
						this.showPageRequestError(response);
					}
				);
			},
			updateFormFields: function(){
				//update form fields value after load from api
				//e.g convert fieldvalue (value,value2,value2) to array 
            },
			resetForm (){
				//reset form fields value
				this.formData = {name: "", username: "", email: "", photo: "", };
				//raise event to reset other custom form components
				//this.$EventBus.$emit("resetForm");
			},
			getErrorClass(field){
			   if(this.v$.formData[field].$invalid && this.submitted){
				    return "p-invalid";
			   }
               return "";
            },
		},
		watch: {
		},
		async mounted() {
		},
		async created() {
		},
	};
</script>
<style scoped>
</style>
<style scoped>

</style>
